/*
 * appuntamenti.js
 * ---------------
 * Exports the appuntamenti page component.
 */

import React from "react";
import PropTypes from "prop-types";

import SiteMetadata from "../components/site-metadata";
import TextPageLayout from "../components/text-page-layout";

const metadata = {
  title: "Appuntamenti",
  description: "Fissa un appuntamento con un nostro consulente dedicato.",
};

const AppuntamentiPage = ({ location }) => (
  <TextPageLayout>
    <SiteMetadata
      location={location}
      {...metadata}
    />

    <h1>{metadata.title}</h1>

    <p>
      Mandaci un messaggio WhatsApp o chiama al
      {" "}
      <a href="tel:+39-340-792-3863">340 792 3863</a>
      .
    </p>
    <p>
      Fissa un appuntamento con un nostro consulente dedicato. Indicaci:
    </p>
    <ul>
      <li>L&apos;argomento (arredi, serramenti, tende, ...).</li>
      <li>Il giorno e l&apos;orario in cui desideri venirci a trovare.</li>
    </ul>
    <p>
      Riceverai un messaggio di conferma per l&apos;appuntamento.
    </p>
  </TextPageLayout>
);

AppuntamentiPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default AppuntamentiPage;
